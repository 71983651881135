import { combineReducers, configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootSlice";
import welcomeModalReducer from './welcomeModalSlice'
import selectPlanReducer from "./selectPlanSlice";
import getInfoReducer from "./getInfoSlice";
import logInSlice from "./logInSlice";
import CollectionSlice from "./myCollection"
import supportSlice from "./supportSlice"
import adminSlice from "./adminSlice"
import editPageSlice from "./editPageSlice"
import submitWorkSlice from "./submitWorkSlice";
import subscriptionSlice from "./subscriptionSlice"
import { adInspirationApi } from "../services/adinspiration-api";
import userSlice from "./userSlice";
import editUserSlice from "./editUserSlice";
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import forgotPasswordSlice from "./forgotPasswordSlice";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['userState', 'adminState', 'logInState']
};

const combinedReducer = combineReducers({
    mainState: rootReducer,
    welcomeModalState: welcomeModalReducer,
    getInfoState: getInfoReducer,
    selectPlanState: selectPlanReducer,
    logInState: logInSlice,
    MyCollectionState: CollectionSlice,
    supportState: supportSlice,
    adminState: adminSlice,
    editPageState: editPageSlice,
    submitPageState: submitWorkSlice,
    subscriptionState: subscriptionSlice,
    userState: userSlice,
    editUserState: editUserSlice,
    forgotPasswordState: forgotPasswordSlice,
    [adInspirationApi.reducerPath]: adInspirationApi.reducer,
})

const persistedReducer = persistReducer(persistConfig, combinedReducer);

export const store = configureStore({
    reducer: persistedReducer,
    // middleware:
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat(adInspirationApi.middleware)
    }
})

export const persistor = persistStore(store);
