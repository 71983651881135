export interface ICreator {
    id: 1;
    creatorImg: string;
    creator: string;
    location: string;
    clapperboard: string;
    message: string;
    socials: {
        tikTok: string;
        instagram: string;
        x: string;
        globe: string;
    };
}

export enum RoleType {
    Free = 1,
    Pro = 2,
    Admin = 3,
    SubAdmin = 4,
}
