import { createSlice } from "@reduxjs/toolkit";
import { adInspirationApi } from "../services/adinspiration-api";


const userSlice = createSlice({
  name: 'userSlice',
  initialState: {
    user: null,
    isAuth: false,
    isFetching: false,
    error: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
      state.isAuth = !!action.payload
    },
    setAuth: (state, action) => {
      state.isAuth = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(adInspirationApi.endpoints?.login.matchFulfilled, (state, {payload}) => {
      state.user = payload.user
      state.isAuth = true
    });
    builder.addMatcher(adInspirationApi.endpoints?.getSession.matchFulfilled, (state, {payload}) => {
      state.user = payload.user
      state.isAuth = true
    });
    builder.addMatcher(adInspirationApi.endpoints?.getSession.matchRejected, (state, {payload}) => {
      state.user = null
      state.isAuth = true
    });
    builder.addMatcher(adInspirationApi.endpoints?.logout.matchFulfilled, (state, _) => {
      state.user = null
      state.isAuth = false
    });
    builder.addMatcher(adInspirationApi.endpoints?.signUp.matchFulfilled, (state, {payload}) => {
      state.user = payload.user
      state.isAuth = true
    });
    builder.addMatcher(adInspirationApi.endpoints?.updateUser.matchFulfilled, (state, {payload}) => {
      state.user = payload.user
      state.isAuth = true
    });
  }
})

export const { setUser, setAuth} = userSlice.actions

export default userSlice.reducer
