import { RoleType } from "./creatorModel";

export interface IUser {
  _id?: string;
  username?: string;
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  accountVerified?: boolean;
  subscriptionEndDate?: Date;
  companyType?: companyType;
  discoveryChannel?: string;
  intentToHire?: string;
  accountManualVerification?: boolean;
  city?: string;
  country?: string;
  website?: string;
  twitter?: string;
  instagram?: string;
  tiktok?: string;
  description?: string;
  rate?: number;
  avatar?: string | any;
  googleId?: string;
  role?: IRole;
  category?: string;
}

export interface IWrapperUser {
  user: IUser;
}

export interface IRole {
  _id: string;
  type: RoleType;
  description: string;
}

export enum companyType {
  Agency = 'Agency',
  Freelance = 'Freelance',
  Software = 'Software Company',
  UGC = 'UGC Creator',
  Brand = 'Brand',
  Other = 'Other',
}
