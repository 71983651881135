import { createSlice } from '@reduxjs/toolkit';

const editPageSlice = createSlice({
    name: 'editPageSlice',
    initialState: {
        editPage: false,
        editCollection: false,
    },
    reducers: {
        editPage: (state, action) => {
            switch (action.payload) {
                case 'open-edit-page':
                    state.editPage = true;
                    break;
                case 'close-edit-page':
                    state.editPage = false;
                    break;
            }
        },
        editCollection: (state, action) => {
            switch (action.payload) {
                case 'open-edit-collection':
                    state.editCollection = true;
                    break;
                case 'close-edit-collection':
                    state.editCollection = false;
                    break;
            }
        }
    }
});

export const { editPage, editCollection } = editPageSlice.actions;
export default editPageSlice.reducer;
