import { createSlice } from '@reduxjs/toolkit';
import { adInspirationApi } from "../services/adinspiration-api";
//import { adInspirationApi } from "../services/adinspiration-api";
//import { RoleType } from "../model/creatorModel";

export const selectPlanSlice = createSlice({
    name: 'selectPlanSlice',
    initialState: {
        selectPlanModal: false,
        paymentDetailsPage: false,
        getUnlimAccessModal: false,
        proPlan: false,
        freePlan: false
    },
    reducers: {
        selectPlan: (state, action) => {
            switch (action.payload) {
                case 'open-select-plan':
                    state.selectPlanModal = true;
                    document.body.style.overflow = 'hidden';

                    break;
                case 'close-select-plan':
                    state.selectPlanModal = false;
                    document.body.style.overflow = 'unset';

                    break;
                case 'go-back':
                    state.selectPlanModal = false;

                    break;
            }
        },
        choicePlan: (state, action) => {
            if (action.payload === 'pro') {
                state.proPlan = true;
                state.freePlan = false;
                state.selectPlanModal = false;
            } else {
                state.freePlan = true;
                state.proPlan = false;
                state.selectPlanModal = false;
            }
        },
        paymentDetails: (state, action) => {
            switch (action.payload) {
                case 'open-payment-details':
                    state.paymentDetailsPage = true;

                    break;
                case 'close-payment-details':
                    state.paymentDetailsPage = false;
                    break;
                // case 'go-to-select-plan':
            }
        },
        getUnlimAccess: (state, action) => {
            switch (action.payload) {
                case 'open-unlim-access':
                    state.getUnlimAccessModal = true;
                    //localStorage.setItem('unlimAccess', 'true');
                    break;
                case 'close-unlim-access':
                    state.getUnlimAccessModal = false;
                    //localStorage.setItem('unlimAccess', 'false');
                    break;
            }
        },
    },
    extraReducers: (builder) => {
        /*builder.addMatcher(adInspirationApi.endpoints?.getSession.matchFulfilled, (state, {payload}) => {
            const getRoleType = payload.user.role.type
            const getUnlimAccess = localStorage.getItem('unlimAccess')
            let unlimAccessModalOpen;
            switch (getRoleType) {
                case RoleType.Free:
                    unlimAccessModalOpen = true;
                    break;
                case RoleType.Pro:
                    unlimAccessModalOpen = false;
                    break;
                default:
                    unlimAccessModalOpen = false;
                    break;
            }
            if (getUnlimAccess === null) {
                state.getUnlimAccessModal = unlimAccessModalOpen;
                localStorage.setItem('unlimAccess',  unlimAccessModalOpen.toString());
            }
        });*/
        builder.addMatcher(adInspirationApi.endpoints?.logout.matchFulfilled, (state, { payload }) => {
            state.getUnlimAccessModal = false;
            localStorage.setItem('unlimAccess', 'false');
        })
    }
});

export const { selectPlan, choicePlan, paymentDetails, getUnlimAccess } = selectPlanSlice.actions;
export default selectPlanSlice.reducer;
