import { createSlice } from "@reduxjs/toolkit";
import { adInspirationApi } from "../services/adinspiration-api";

const editUserSlice = createSlice({
  name: 'editUserSlice',
  initialState: {
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    city: '',
    country: '',
    description: '',
    rate: '',
    website: '',
    instagram: '',
    twitter: '',
    tiktok: '',
    oldPassword: '',
    newPassword: '',
    avatar: '',
  },
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload
    },
    setLastName: (state, action) => {
      state.lastName = action.payload
    },
    setEmail: (state, action) => {
      state.email = action.payload
    },
    setCity: (state, action) => {
      state.city = action.payload
    },
    setCountry: (state, action) => {
      state.country = action.payload
    },
    setDescription: (state, action) => {
      state.description = action.payload
    },
    setRate: (state, action) => {
      state.rate = action.payload
    },
    setWebsite: (state, action) => {
      state.website = action.payload
    },
    setInstagram: (state, action) => {
      state.instagram = action.payload
    },
    setTwitter: (state, action) => {
      state.twitter = action.payload
    },
    setTiktok: (state, action) => {
      state.tiktok = action.payload
    },
    setAvatar: (state, action) => {
      state.avatar = action.payload
    },
    setOldPassword: (state, action) => {
      state.oldPassword = action.payload
    },
    setNewPassword: (state, action) => {
      state.newPassword = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(adInspirationApi.endpoints?.getSession.matchFulfilled, (state, {payload}) => {
      state.username = payload.user?.username
      state.firstName = payload.user.firstName
      state.lastName = payload.user.lastName
      state.email = payload.user.email
      state.city = payload.user?.city
      state.country = payload.user?.country
      state.description = payload.user?.description
      state.rate = payload.user?.rate
      state.website = payload.user?.website
      state.instagram = payload.user?.instagram
      state.twitter = payload.user?.twitter
      state.tiktok = payload.user?.tiktok
      state.avatar = payload.user?.avatar
    });
  }
});

export const { setUsername, setFirstName, setLastName, setEmail, setCity, setCountry, setDescription, setRate, setWebsite, setInstagram, setTwitter, setTiktok, setOldPassword, setNewPassword, setAvatar } = editUserSlice.actions;

export default editUserSlice.reducer;
