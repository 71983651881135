import { createApi, fetchBaseQuery, QuerySubState, RootState } from "@reduxjs/toolkit/query/react";
import { OneThumb, WrapperThumb } from "../model/cardModel";
import { companyType, IUser, IWrapperUser } from "../model/user";
import { CollectionIdsAndNamesFromCurrentUser, ICollections } from "../model/collection";

interface IPagination {
  page?: number;
  limit?: number;
  category?: Category;
}

export enum Category {
  AppDigital = 'App / Digital',
  ApparelFashion = 'Apparel / Fashion',
  BeautyPersonalCare = 'Beauty / Personal Care',
  Electronics = 'Electronics / Technology',
  FoodBeverage = 'Food / Beverage',
  HealthWellness = 'Health / Wellness',
  Home = 'Home',
  KidsBabies = 'Kids / Babies',
  LocalServices = 'Local Services / Businesses',
  Pets = 'Pets',
  Other = 'Other',
}

// @ts-ignore
export const adInspirationApi = createApi({
  reducerPath: 'adInspirationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'include',
  }),
  tagTypes: ['Video', 'VideosBrands', 'AdsFromUser', 'Collection'],
  endpoints: (builder) => ({
    // Videos ads
    getAds: builder.query<WrapperThumb, IPagination>({
      query: ({page = 1, limit = 24, category}) => `/video?page=${page}&limit=${limit}&category=${category}`,
      serializeQueryArgs: ({endpointName}) => {
        return endpointName
      },
      merge: (currentCacheData, responseData, { arg }) => {
        // Ensure the videos array exists in the cache

        // Determine if the incoming category is valid and not a falsy value (e.g., not undefined, not null, and not an empty string)
        // However, also account for the scenario where category is explicitly undefined when page is 1
        const isValidIncomingCategory = arg.category !== undefined && arg.category !== null;
        const isInitialPageWithoutCategory = arg.page === 1 && arg.category === undefined;

        // Check if there are any videos in the cache with a different category from the incoming category,
        // considering only valid incoming category cases
        const cacheHasDifferentCategory = isValidIncomingCategory && currentCacheData.videos.some(video => video.category !== arg.category);

        // If there's a valid category change and it's the first page, or if the categories are different,
        // or if it's the first page and category is explicitly undefined, clear the cache.
        if ((isValidIncomingCategory && (arg.page === 1 || cacheHasDifferentCategory)) || isInitialPageWithoutCategory) {
          currentCacheData.videos = [];
          currentCacheData.total = 0;
        }

        // Create a set for quick lookup of existing video IDs to avoid duplicates
        const existingVideoIds = new Set(currentCacheData.videos.map(video => video._id));

        // Filter responseData to include only videos not already present in the cache
        const newVideos = responseData.videos.filter(video => !existingVideoIds.has(video._id));

        // Append new, non-duplicated videos
        currentCacheData.videos.push(...newVideos);

        // Update total with the most recent total value
        currentCacheData.total = responseData.total;
      },

      forceRefetch({currentArg, previousArg}) {
        // @ts-ignore
        return (currentArg?.page !== previousArg?.page && currentArg?.page > previousArg?.page && currentArg?.category !== previousArg?.category)
      },
      providesTags: ['Video']
    }),
    getAdsSearch: builder.query<WrapperThumb, IPagination & { search: string }>({
      query: ({ search, page = 1, limit = 24 }) => `/video/search/videos?search=${search}&page=${page}&limit=${limit}`,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCacheData, responseData, otherArgs) => {
        // Check if it's a new search or pagination request
        if (otherArgs.arg.page === 1) {
          // If it's a new search (or the first page), replace the cache entirely with the new response data
          return responseData;
        } else {
          // If it's a pagination request, append the new results
          currentCacheData.videos.push(...responseData.videos);
          currentCacheData.total = responseData.total;
          return currentCacheData;
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.search !== previousArg?.search || currentArg?.page !== previousArg?.page;
      },
    }),

    getAd: builder.query<OneThumb, string | undefined>({
      query: (videoId) => `/video/one/${videoId}`,
      serializeQueryArgs: ({endpointName}) => {
        return endpointName
      },
    }),
    getAdsFromUser: builder.query<WrapperThumb,IPagination & { userId?: string}>({
      query: ({userId, page = 1, limit = 24, category}) => `/video/${userId}?page=${page}&limit=${limit}&category=${category}`,
      serializeQueryArgs: ({endpointName}) => {
        return endpointName
      },
      merge: (currentCacheData, responseData, {arg}) => {
        // Ensure the videos array exists in the cache
        currentCacheData.videos = currentCacheData.videos || [];

        // Determine if the incoming category is valid and not a falsy value (e.g., not undefined, null, etc.)
        // @ts-ignore
        const isValidIncomingCategory = arg.category !== undefined && arg.category !== null && arg.category !== '';

        // Check if there are any videos in the cache with a different category from the incoming category,
        // considering only valid incoming category cases
        const cacheHasDifferentCategory = isValidIncomingCategory && currentCacheData.videos.some(video => video.category !== arg.category);

        // If there's a valid category change and it's the first page, or if the categories are different,
        // clear the cache. This logic now explicitly considers cases where the category is meaningful.
        if (isValidIncomingCategory && (arg.page === 1 || cacheHasDifferentCategory)) {
          currentCacheData.videos = [];
          currentCacheData.total = 0;
        }

        // Create a set for quick lookup of existing video IDs to avoid duplicates
        const existingVideoIds = new Set(currentCacheData.videos.map(video => video._id));

        // Filter responseData to include only videos not already present in the cache
        const newVideos = responseData.videos.filter(video => !existingVideoIds.has(video._id));

        // Append new, non-duplicated videos
        currentCacheData.videos.push(...newVideos);

        // Update total with the most recent total value
        currentCacheData.total = responseData.total;
      },
      providesTags: ['AdsFromUser'],

      forceRefetch({currentArg, previousArg}) {
        // @ts-ignore
        return (currentArg?.page !== previousArg?.page || currentArg?.userId !== previousArg?.userId) && currentArg?.page > previousArg?.page
      }
    }),
    getAdsFromCollection: builder.query<WrapperThumb & {user: {firstName?: string, avatar?: string, _id: string}, name: string, description: string}, {collectionId?: string}>({
      query: ({collectionId}) => `/collection/from/videos/${collectionId}`,
      serializeQueryArgs: ({endpointName}) => {
        return endpointName
      },
      forceRefetch({currentArg, previousArg}) {
        return currentArg?.collectionId !== previousArg?.collectionId
      },
      providesTags: (result, error, {collectionId}) => [{ type: 'Collection', id: collectionId }],
    }),
    deleteAd: builder.mutation<any, {videoId?: string}>({
      query: ({videoId}) => ({
        url: `/video/${videoId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Video']
    }),
    getAdsFromBrand: builder.query<WrapperThumb, IPagination & {userId?: string}>({
      query: ({userId, page = 1, limit = 24}) => `/video/brand/${userId}?page=${page}&limit=${limit}`,
      serializeQueryArgs: ({endpointName}) => {
        return endpointName
      },
      merge: (currentCacheData, responseData, otherArgs) => {
        currentCacheData.videos.push(...responseData.videos);
        currentCacheData.total = responseData.total;
      },
      forceRefetch({currentArg, previousArg}) {
        // @ts-ignore
        return (currentArg?.page !== previousArg?.page || currentArg?.userId !== previousArg?.userId) || currentArg?.page > previousArg?.page
      },
      providesTags: (result, error, { userId }) => [{ type: 'VideosBrands', id: userId }],
    }),
    getCountCategories: builder.query<any, string | undefined>({
      query: (userId) => `/video/users/categories/${userId}`,
    }),
    createVideoUser: builder.mutation<any, {title?: string, description?: string, videoUrl: string, platformType: string[], category: string, originalLink?: string, brandName: string, video: File | null, userId?: string}>({
      query(video) {
        const params = new FormData();
        params.append('videoUrl', video.videoUrl);
        params.append('platformType', JSON.stringify(video.platformType));
        params.append('category', video.category);
        params.append('brandName', video.brandName);
        if (video.video) {
          params.append('video', video.video);
        }
        if (video.originalLink)
          params.append('originalLink', video.originalLink);
        if (video.description)
          params.append('description', video.description);
        if (video.title)
          params.append('title', video.title);
        if (video.userId)
          params.append('userId', video.userId);
        return {
          url: '/video',
          method: 'POST',
          body: params,
        };
      }
    }),
    // Migrations
    migrateData: builder.mutation<any, {xlsxFile: File}>({
      query: ({ xlsxFile }) => {
        const params = new FormData();
        params.append('xlsx', xlsxFile);
        return {
          url: '/migration/ugc',
          method: 'POST',
          body: params,
        };
      }
    }),
    migrateDataBrands: builder.mutation<any, {xlsxFile: File}>({
      query: ({ xlsxFile }) => {
        const params = new FormData();
        params.append('xlsx', xlsxFile);
        return {
          url: '/migration/brands',
          method: 'POST',
          body: params,
        };
      }
    }),
    // Emails
    sendFeedbackEmail: builder.mutation<any, {type: string, title: string, message: string}>({
      query(feedback) {
        const params = new URLSearchParams();
        params.append('type', feedback.type);
        params.append('title', feedback.title);
        params.append('message', feedback.message);

        return {
          url: '/email/feedback',
          method: 'POST',
          body: params.toString(),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
      },
    }),
    // Collections
    getCollectionsFromUser: builder.query<ICollections, IPagination & {userId?: string}>({
      query: ({userId, page = 1, limit = 24}) => `/collection/${userId}/user?page=${page}&limit=${limit}`,
      serializeQueryArgs: ({endpointName}) => {
        return endpointName
      },
      merge: (currentCacheData, responseData, otherArgs) => {
        if (otherArgs.arg.userId !== undefined && otherArgs.arg.page) {
          currentCacheData.data = [];
          currentCacheData.total = 0
        } if (currentCacheData.data && responseData.data) {
          currentCacheData.data.push(...responseData.data);
        }
        currentCacheData.total = responseData.total;
      },
      forceRefetch({currentArg, previousArg}) {
        return currentArg?.page !== previousArg?.page || currentArg?.userId !== previousArg?.userId
      }
    }),
    getCollectionsIdsAndNamesFromUser: builder.query<CollectionIdsAndNamesFromCurrentUser, void>({
      query: () => `/collection/user/from/user/`,
      serializeQueryArgs: ({endpointName}) => {
        return endpointName
      },
      forceRefetch(params: {
        currentArg: undefined;
        previousArg:undefined;
        state: RootState<any, any, string>;
        endpointState?: QuerySubState<any>
      }): boolean {
        return true
      }
    }),
    addVideoToCollection: builder.mutation<any, {collectionId: string, videoId: string}>({
      query({collectionId, videoId}) {
        const params = new URLSearchParams();
        params.append('videoId', videoId);
        return {
          url: `/collection/video/collection/${collectionId}`,
          method: 'PUT',
          body: params.toString(),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
      },
    }),
    createCollection: builder.mutation<any, {name: string, description: string, videos: string}>({
      query({name, description, videos}) {
        const params = new URLSearchParams();
        params.append('name', name);
        params.append('description', description);
        params.append('videos', videos);

        return {
          url: `/collection`,
          method: 'POST',
          body: params.toString(),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
      }
    }),
    deleteCollection: builder.mutation<any, {collectionId?: string}>({
      query({collectionId}) {
        return {
          url: `/collection/${collectionId}`,
          method: 'DELETE',
        };
      }
    }),
    addVideoToFavoriteCollection: builder.mutation<any, { videoId: string}>({
      query({ videoId}) {
        return {
          url: `/collection/video/${videoId}`,
          method: 'PUT',
        };
      }
    }),
    removeVideoFromFavoriteCollection: builder.mutation<any, {videoId: string}>({
      query({ videoId}) {
        return {
          url: `/collection/video/${videoId}`,
          method: 'DELETE',
        };
      }
    }),
    getCollection: builder.query<any, {collectionId?: string}>({
      query: ({ collectionId }) => `/collection/${collectionId}`,
    }),
    updateCollection: builder.mutation<any, {name: string, description: string, collectionId: string}>({
      query({name, description, collectionId}) {
        const params = new URLSearchParams();
        params.append('name', name);
        params.append('description', description);
        return {
          url: `/collection/${collectionId}`,
          method: 'PUT',
          body: params.toString(),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
      }
    }),
    deleteVideoFromCollection: builder.mutation<any, {collectionId?: string, videoId?: string}>({
      query({collectionId, videoId}) {
        return {
          url: `/collection/video/collection/${collectionId}?videoId=${videoId}`,
          method: 'DELETE',
        };
      }
    }),
    // Login (Sessions related)
    login: builder.mutation<any, { email: string, password: string }>({
      query(credentials) {
        const params = new URLSearchParams();
        params.append('email', credentials.email);
        params.append('password', credentials.password);

        return {
          url: '/auth/login',
          method: 'POST',
          body: params.toString(),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
      },
    }),
    signUp: builder.mutation<any, { email: string, password: string, firstName: string, lastName: string, companyType: companyType, intentToHire: string, discoveryChannel: string }>({
      query(credentials) {
        const params = new URLSearchParams();
        params.append('email', credentials.email);
        params.append('password', credentials.password);
        params.append('firstName', credentials.firstName);
        params.append('lastName', credentials.lastName);
        params.append('companyType', credentials.companyType);
        params.append('intentToHire', credentials.intentToHire);
        params.append('discoveryChannel', credentials.discoveryChannel);

        return {
          url: '/auth/sign-up',
          method: 'POST',
          body: params.toString(),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
      },
    }),
    updateUser: builder.mutation<any, IUser & {oldPassword?: string, newPassword?: string, email?: string}>({
      query(user) {
        const params = new FormData();
        params.append('firstName', user.firstName);
        params.append('lastName', user.lastName);
        if (user.email)
          params.append('email', user.email);
        if (user.intentToHire)
          params.append('intentToHire', user.intentToHire);
        if (user.discoveryChannel)
          params.append('discoveryChannel', user.discoveryChannel);
        if (user.companyType)
          params.append('companyType', user.companyType);
        if (user.avatar)
          params.append('avatar', user.avatar);
        if (user.description)
          params.append('description', user.description);
        if (user.city)
          params.append('city', user.city);
        if (user.country)
          params.append('country', user.country);
        if (user.website)
          params.append('website', user.website);
        if (user.twitter)
          params.append('twitter', user.twitter);
        if (user.instagram)
          params.append('instagram', user.instagram);
        if (user.tiktok)
          params.append('tiktok', user.tiktok);
        if (user.username)
          params.append('username', user.username);
        if (user.rate)
          params.append('rate', user.rate.toString());

        return {
          url: '/auth',
          method: 'PUT',
          body: params,
        };
      }
    }),
    getSession: builder.query<any, void>({
      query: () => '/auth/session',
    }),
    logout: builder.query<any, void>({
      query: () => '/auth/logout',
    }),
    checkEmail: builder.query<any, { email: string }>({
      query: (email) => `/auth/is-available?email=${email}`,
    }),
    getUser: builder.query<IWrapperUser, string | undefined>({
      query: (userId) => `/auth/${userId}`,
    }),
    changePassword: builder.mutation<any, {oldPassword: string, newPassword: string}>({
      query(newPassword) {
        const params = new URLSearchParams();
        params.append('newPassword', newPassword.newPassword);
        params.append('oldPassword', newPassword.oldPassword);

        return {
          url: '/auth/update-password',
          method: 'PUT',
          body: params.toString(),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
      }
    }),
    getEmails: builder.query<any, void>({
      query: () => '/auth/users/emails',
    }),
    getRoles: builder.query<any, void>({
      query: () => '/auth/users/roles',
    }),
    getUsers: builder.query<any, void>({
      query: () => '/auth',
    }),
    checkout: builder.mutation<{url: string}, void>({
      query: () => {
        return {
          url: '/checkout',
          method: 'POST',
        };
      },
    }),
    checkoutPro: builder.mutation<{url: string}, void>({
      query: () => {
        return {
          url: '/checkout/pro',
          method: 'POST',
        };
      },
    }),
    createUser: builder.mutation<any, IUser & {password: string, category: string }>({
      query(user) {
        const params = new FormData();
        params.append('firstName', user.firstName);
        params.append('lastName', user.lastName);
        params.append('email', user.email);
        params.append('password', user.password);
        params.append('category', user.category);
        if (user.companyType)
          params.append('companyType', user.companyType);
        if (user.role)
          params.append('role', user.role.type.toString());
        if (user.description)
          params.append('description', user.description);
        if (user.intentToHire)
          params.append('intentToHire', user.intentToHire);
        if (user.discoveryChannel)
          params.append('discoveryChannel', user.discoveryChannel);
        if (user.avatar)
          params.append('avatar', user.avatar);
        if (user.city)
          params.append('city', user.city);
        if (user.website)
          params.append('website', user.website);
        if (user.twitter)
          params.append('twitter', user.twitter);
        if (user.instagram)
          params.append('instagram', user.instagram);
        if (user.tiktok)
          params.append('tiktok', user.tiktok);
        if (user.username)
          params.append('username', user.username);
        if (user.rate)
          params.append('rate', user.rate.toString());

        return {
          url: '/auth/user/create',
          method: 'POST',
          body: params,
        };
      }
    }),
    // Passwords
    forgetPassword: builder.mutation<any, {email: string}>({
      query({email}) {
        const params = new URLSearchParams();
        params.append('email', email);

        return {
          url: '/auth/forgot-password',
          method: 'POST',
          body: params.toString(),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
      }
    }),
    verifyValidToken: builder.query<any, {token: string | null, id: string | null}>({
      query({token, id}) {
        const params = new URLSearchParams();
        if (token && id) {
          params.append('token', token);
          params.append('userId', id);
        }
        return {
          url: '/auth/verify-pass-reset-token',
          method: 'POST',
          body: params.toString(),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
      }
    }),
    resetPassword: builder.mutation<any, {token: string, id: string, password: string}>({
      query({token, id, password}) {
        const params = new URLSearchParams();
        params.append('token', token);
        params.append('userId', id);
        params.append('newPassword', password);
        return {
          url: '/auth/reset-password',
          method: 'POST',
          body: params.toString(),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
      }
    }),
  }),
});

export const {useForgetPasswordMutation, useResetPasswordMutation, useVerifyValidTokenQuery, useGetAdsQuery, useGetEmailsQuery, useLoginMutation, useGetSessionQuery, useLazyLogoutQuery, useLazyCheckEmailQuery, useSignUpMutation, useUpdateUserMutation, useGetUserQuery, useChangePasswordMutation, useGetAdsFromUserQuery, useGetCollectionsFromUserQuery, useGetAdsFromCollectionQuery, useAddVideoToFavoriteCollectionMutation, useRemoveVideoFromFavoriteCollectionMutation, useGetAdsFromBrandQuery, useGetCollectionsIdsAndNamesFromUserQuery, useAddVideoToCollectionMutation, useCreateCollectionMutation, useCheckoutMutation, useGetAdsSearchQuery, useGetAdQuery, useDeleteCollectionMutation, useCreateVideoUserMutation, useGetRolesQuery, useCreateUserMutation, useMigrateDataMutation, useMigrateDataBrandsMutation, useDeleteAdMutation, useGetCountCategoriesQuery, useSendFeedbackEmailMutation, useGetCollectionQuery, useUpdateCollectionMutation, useDeleteVideoFromCollectionMutation, useCheckoutProMutation, useGetUsersQuery} = adInspirationApi;
