import React, { useRef, useEffect } from 'react';

import closeBtn from '../../assets/images/closeBtn.svg';

import './SearchFilters.scss';
import { useSelector, useDispatch } from 'react-redux';
import { popup } from '../../redux/rootSlice';
import { Link, useLocation, useNavigate } from 'react-router-dom';

//swiper
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { Category } from "../../services/adinspiration-api";

const SearchFilters = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
    const filterPopup = useSelector((state: any) => state.mainState.filterPopup);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const category = searchParams.get('category');
    const guestStatus = useSelector((state: any) => state.logInState.guestStatus);

    const callModal = (btn: string) => {
        const toggleFilter = () => {
            //filterPopup ? dispatch(popup('close-filter')) : dispatch(popup('open-filter'));
        };

        switch (btn) {
            case 'filter':
                // guestStatus ? dispatch(popup('open-signIn')): dispatch(popup('open-filter'));
                guestStatus ? dispatch(popup('open-signIn')) : toggleFilter();

                break;
            case 'search':

                break;
        }
    };

    function useOutsideAlerter(ref: any) {
        // const dispatch = useDispatch();

        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    dispatch(popup('close-filter'));
                }
            }
            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }

    // const arrowDown = require('../../assets/images/arrowDown.svg') as string;

    return (
        <>
            <div className='searchFilterWrapper' style={guestStatus ? {} : {}}>
                <style>
                    {!guestStatus && (
                      <style>
                          {`.searchFilterWrapper::before {
                            display: none;
                        }`}
                      </style>
                    )}
                </style>
                <div
                    className='search'
                    onKeyUp={
                        (e) => {
                            if (e.key === 'Enter') {
                                if (guestStatus) {
                                    dispatch(popup('open-signIn'));
                                } else {
                                    navigate(`/search-page?search=${(e.target as HTMLInputElement).value}`);
                                }
                            }
                        }
                    }
                    onTouchStart={() => {
                      dispatch(popup('open-signIn'));
                    }}
                    onClick={() => {
                        callModal('search');
                    }}
                >
                    <div className='searchDesktop'>
                        <input type='text' placeholder='Search' />
                    </div>
                    <div className='searchMobile'>{/* <img src={searchMobileBtn} alt="search-mobile" /> */}</div>
                </div>
                <div ref={wrapperRef}>
                    {/* <div className="filterWrapper" onClick={() => {callModal('filter')}}>
                        <span>Filters</span>
                        <img src={arrowDown} alt="" />

                    </div> */}

                    {filterPopup && (
                        <div className='filterPopup'>
                            <div className='filterPopupContainer'>
                                <div className='closeBtn'>
                                    <img src={closeBtn} alt='close-btn' onClick={() => dispatch(popup('close-filter'))} />
                                </div>
                                <div className='categories'>
                                    <div className='optionTitle'>
                                        <span>Categories:</span>
                                    </div>
                                    <div className='categoryWrapper'>
                                        <div className='category' onClick={() => {
                                            navigate(`/?category=${Category.AppDigital}`);
                                            window.location.reload();
                                        }}>
                                            <div className='categoryName'>
                                                <span>App / Digital Products</span>
                                            </div>
                                            <div className='categoryCount'>
                                                <span>823</span>
                                            </div>
                                        </div>
                                        <div className='category' onClick={() => {
                                            navigate(`/?category=${Category.ApparelFashion}`);
                                            window.location.reload();
                                        }}>
                                            <div className='categoryName'>
                                                <span>Apparel / Fashion</span>
                                            </div>
                                            <div className='categoryCount'>
                                                <span>823</span>
                                            </div>
                                        </div>
                                        <div className='category' onClick={() => {
                                            navigate(`/?category=${Category.BeautyPersonalCare}`);
                                            window.location.reload();
                                        }}>
                                            <div className='categoryName'>
                                                <span>Beauty / Personal Care</span>
                                            </div>
                                            <div className='categoryCount'>
                                                <span>123</span>
                                            </div>
                                        </div>
                                        <div className='category' onClick={() => {
                                            navigate(`/?category=${Category.Electronics}`);
                                            window.location.reload();
                                        }}>
                                            <div className='categoryName'>
                                                <span>Electronics / Tech</span>
                                            </div>
                                            <div className='categoryCount'>
                                                <span>823</span>
                                            </div>
                                        </div>
                                        <div className='category' onClick={() => {
                                            navigate(`/?category=${Category.FoodBeverage}`);
                                            window.location.reload();
                                        }}>
                                            <div className='categoryName'>
                                                <span>Food / Beverage</span>
                                            </div>
                                            <div className='categoryCount'>
                                                <span>823</span>
                                            </div>
                                        </div>
                                        <div className='category' onClick={() => {
                                            navigate(`/?category=${Category.HealthWellness}`);
                                            window.location.reload();
                                        }}>
                                            <div className='categoryName'>
                                                <span>Health / Wellness</span>
                                            </div>
                                            <div className='categoryCount'>
                                                <span>823</span>
                                            </div>
                                        </div>
                                        <div className='category' onClick={() => {
                                            navigate(`/?category=${Category.Home}`);
                                            window.location.reload();
                                        }}>
                                            <div className='categoryName'>
                                                <span>Home</span>
                                            </div>
                                            <div className='categoryCount'>
                                                <span>823</span>
                                            </div>
                                        </div>
                                        <div className='category' onClick={() => {
                                            navigate(`/?category=${Category.KidsBabies}`);
                                            window.location.reload();
                                        }}>
                                            <div className='categoryName'>
                                                <span>Kids / Babies</span>
                                            </div>
                                            <div className='categoryCount'>
                                                <span>632</span>
                                            </div>
                                        </div>
                                        <div className='category' onClick={() => {
                                            navigate(`/?category=${Category.LocalServices}`);
                                            window.location.reload();
                                        }}>
                                            <div className='categoryName'>
                                                <span>Local Services / Businesses</span>
                                            </div>
                                            <div className='categoryCount'>
                                                <span>823</span>
                                            </div>
                                        </div>
                                        <div className='category' onClick={() => {
                                            navigate(`/?category=${Category.Pets}`);
                                            window.location.reload();
                                        }}>
                                            <div className='categoryName'>
                                                <span>Pets</span>
                                            </div>
                                            <div className='categoryCount'>
                                                <span>98</span>
                                            </div>
                                        </div>
                                        <div className='category' onClick={() => {
                                            navigate(`/?category=${Category.Other}`);
                                            window.location.reload();
                                        }}>
                                            <div className='categoryName'>
                                                <span>Other</span>
                                            </div>
                                            <div className='categoryCount'>
                                                <span>823</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='platforms'>
                                    <div className='optionTitle'>
                                        <span>Platform:</span>
                                    </div>
                                    <div className='platformsWrapper'>
                                        <div className='category'>
                                            <div className='categoryName'>
                                                <span>Facebook & Instagram</span>
                                            </div>
                                            <div className='categoryCount'>
                                                <span>122</span>
                                            </div>
                                        </div>
                                        <div className='category'>
                                            <div className='categoryName'>
                                                <span>TikTok</span>
                                            </div>
                                            <div className='categoryCount'>
                                                <span>123</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <Swiper slidesPerView='auto' spaceBetween={10} navigation={true} modules={[Navigation]} className='mySwiper swiper'>
                    <SwiperSlide className="swiper-slide-custom swiper-slide">
                        <Link
                          to="#"
                          className={"swiper-link" + (category === Category.AppDigital.toString() ? ' active' : '')}
                          onClick={(e) => {
                              e.preventDefault(); // Prevent the default link action
                              if (guestStatus) {
                                  dispatch(popup('open-signIn'));
                              } else if (category === Category.AppDigital.toString()) {
                                  navigate(`/`); // Navigate to homepage if the category is already selected
                              } else {
                                  navigate(`/?category=${Category.AppDigital}`); // Navigate to the selected category
                              }
                          }}
                        >
                            App / Digital
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link
                            to="#"
                            className={"swiper-link" + (category === Category.ApparelFashion.toString() ? ' active' : '')}
                            onClick={(e) => {
                                e.preventDefault(); // Prevent the default link action
                                if (guestStatus) {
                                    dispatch(popup('open-signIn'));
                                } else if (category === Category.ApparelFashion.toString()) {
                                    navigate(`/`); // Navigate to homepage if the category is already selected
                                } else {
                                    navigate(`/?category=${Category.ApparelFashion}`); // Navigate to the selected category
                                }
                            }}
                        >
                            Apparel / Fashion
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link
                            to="#"
                            className={"swiper-link" + (category === Category.BeautyPersonalCare.toString() ? ' active' : '')}
                            onClick={(e) => {
                                e.preventDefault(); // Prevent the default link action
                                if (guestStatus) {
                                    dispatch(popup('open-signIn'));
                                } else if (category === Category.BeautyPersonalCare.toString()) {
                                    navigate(`/`); // Navigate to homepage if the category is already selected
                                } else {
                                    navigate(`/?category=${Category.BeautyPersonalCare}`); // Navigate to the selected category
                                }
                            }}
                        >
                            Beauty / Personal Care
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link
                            to="#"
                            className={"swiper-link" + (category === Category.Electronics.toString()  ? ' active' : '')}
                            onClick={(e) => {
                                e.preventDefault(); // Prevent the default link action
                                if (guestStatus) {
                                    dispatch(popup('open-signIn'));
                                } else if (category === Category.Electronics.toString()) {
                                    navigate(`/`); // Navigate to homepage if the category is already selected
                                } else {
                                    navigate(`/?category=${Category.Electronics}`); // Navigate to the selected category
                                }
                            }}
                        >
                            Electronics / Tech
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link
                            to="#"
                            className={"swiper-link" + (category === Category.FoodBeverage.toString() ? ' active' : '')}
                            onClick={(e) => {
                                e.preventDefault(); // Prevent the default link action
                                if (guestStatus) {
                                    dispatch(popup('open-signIn'));
                                } else if (category === Category.FoodBeverage) {
                                    navigate(`/`); // Navigate to homepage if the category is already selected
                                } else {
                                    navigate(`/?category=${Category.FoodBeverage}`); // Navigate to the selected category
                                }
                            }}
                        >
                            Food / Beverage
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link
                            to="#"
                            className={"swiper-link" + (category === Category.HealthWellness.toString() ? ' active' : '')}
                            onClick={(e) => {
                                e.preventDefault(); // Prevent the default link action
                                if (guestStatus) {
                                    dispatch(popup('open-signIn'));
                                } else if (category === Category.HealthWellness) {
                                    navigate(`/`); // Navigate to homepage if the category is already selected
                                } else {
                                    navigate(`/?category=${Category.HealthWellness}`); // Navigate to the selected category
                                }
                            }}
                        >
                            Health / Wellness
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link
                            to="#"
                            className={"swiper-link" + (category === Category.Home.toString() ? ' active' : '')}
                            onClick={(e) => {
                                e.preventDefault(); // Prevent the default link action
                                if (guestStatus) {
                                    dispatch(popup('open-signIn'));
                                } else if (category === Category.Home) {
                                    navigate(`/`); // Navigate to homepage if the category is already selected
                                } else {
                                    navigate(`/?category=${Category.Home}`); // Navigate to the selected category
                                }
                            }}
                        >
                            Home
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link
                          to="#"
                          className={"swiper-link" + (category === Category.KidsBabies ? ' active' : '')}
                          onClick={(e) => {
                              e.preventDefault(); // Prevent the default link action
                              if (guestStatus) {
                                  dispatch(popup('open-signIn'));
                              } else if (category === Category.KidsBabies) {
                                  navigate(`/`); // Navigate to homepage if the category is already selected
                              } else {
                                  navigate(`/?category=${Category.KidsBabies}`); // Navigate to the selected category
                              }
                          }}
                        >
                            Kids / Babies
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link
                          to="#"
                          className={"swiper-link" + (category === Category.LocalServices.toString() ? ' active' : '')}
                          onClick={(e) => {
                              e.preventDefault(); // Prevent the default link action
                              if (guestStatus) {
                                  dispatch(popup('open-signIn'));
                              } else if (category === Category.LocalServices) {
                                  navigate(`/`); // Navigate to homepage if the category is already selected
                              } else {
                                  navigate(`/?category=${Category.LocalServices}`); // Navigate to the selected category
                              }
                          }}
                        >
                            Local Services / Businesses
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link
                          to="#"
                          className={"swiper-link" + (category === Category.Pets ? ' active' : '')}
                          onClick={(e) => {
                              e.preventDefault(); // Prevent the default link action
                              if (guestStatus) {
                                  dispatch(popup('open-signIn'));
                              } else if (category === Category.Pets) {
                                  navigate(`/`); // Navigate to homepage if the category is already selected
                              } else {
                                  navigate(`/?category=${Category.Pets}`); // Navigate to the selected category
                              }
                          }}
                        >
                            Pets
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link
                          to="#"
                          className={"swiper-link" + (category === Category.Other ? ' active' : '')}
                          onClick={(e) => {
                              e.preventDefault(); // Prevent the default link action
                              if (guestStatus) {
                                  dispatch(popup('open-signIn'));
                              } else if (category === Category.Other) {
                                  navigate(`/`); // Navigate to homepage if the category is already selected
                              } else {
                                  navigate(`/?category=${Category.Other}`); // Navigate to the selected category
                              }
                          }}
                        >
                            Other
                        </Link>
                    </SwiperSlide>
                </Swiper>
            </div>
        </>
    );
};

export default SearchFilters;
