import { createSlice } from '@reduxjs/toolkit';

const forgotPasswordSlice = createSlice({
    name: 'forgotPasswordSlice',
    initialState: {
        forgotPasswordModal: false,
        forgotPasswordSuccessModal: false,
    },
    reducers: {
        forgotPassword: (state, action) => {
            switch (action.payload) {
                case 'open-forgot-password':
                    state.forgotPasswordModal = true;
                    document.body.style.overflow = 'hidden';
                    break;
                case 'close-forgot-password':
                    state.forgotPasswordModal = false;
                    document.body.style.overflow = 'unset';
                    break;
            }
        },
        forgotPasswordSuccess: (state, action) => {
            switch (action.payload) {
                case 'open-forgot-password-success':
                    state.forgotPasswordSuccessModal = true;
                    document.body.style.overflow = 'hidden';
                    break;
                case 'close-forgot-password-success':
                    state.forgotPasswordSuccessModal = false;
                    document.body.style.overflow = 'unset';
                    break;
            }
        }
    }
});

export const { forgotPassword, forgotPasswordSuccess } = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
