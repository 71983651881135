import React, { useEffect, useRef } from 'react';

import './Header.scss';
import './MediaHeader.scss';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { popup } from '../../redux/rootSlice';
import userProfileImg from '../../assets/images/user-avatar.png';
import { Link } from 'react-router-dom';
import { getUnlimAccess } from '../../redux/selectPlanSlice';
import { useNavigate } from 'react-router-dom';
import { openSupportModal } from '../../redux/supportSlice';
import { useLazyLogoutQuery } from "../../services/adinspiration-api";
import { persistor } from "../../redux";
import { usePostHog } from "posthog-js/react";

const Header = () => {
    const dispatch = useDispatch();
    const posthog = usePostHog();
    const navigate = useNavigate();
    const profileRef = useRef(null);
    useOutsideAlerter(profileRef);

    const guestStatus = useSelector((state: any) => state.logInState.guestStatus);

    const profilePopup = useSelector((state: any) => state.mainState.profilePopup);

    const userFree = useSelector((state: any) => state.logInState.userFree);

    const user = useSelector((state: any) => state.userState.user);

    const [triggerLogout] = useLazyLogoutQuery();

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    dispatch(popup('close-profile-popup'));
                }
            }
            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }

    // const collectionState = useSelector((state: any) => state.MyCollectionState.collectionState)

    // const checkCollectionState = (e: any) => {

    //     if (collectionState) {

    //     } else {

    //       e.preventDefault()
    //       dispatch(popupMethod('yourCollection'))
    //       // dispatch(changeCollectionState())

    //     }

    // }


    return (
        <div className='container'>
            <header className='App-header'>
                <div className='pageMenu'>
                    <Link to='/' >
                        <div className='Page-Name log-btn go-pro'>Gallery</div>
                    </Link>
                    {/* <Link>

            </Link> */}
                    {user && (
                        <Link to={`/collection-page/user/${user.id}`} className='Page-Name log-btn go-pro'>
                            My Collections
                        </Link>
                    )}
                </div>

                <a className='App-link Title-Page' href='/'>
                    Adinspiration
                </a>

                <div className='login-menu'>
                    {!user && (
                        <button className='log-btn' onClick={() => dispatch(popup('open-logIn'))}>
                            Log in
                        </button>
                    )}
                    {user?.role.type === 1 && (
                        <button
                            className='log-btn go-pro'
                            onClick={() => {
                                dispatch(getUnlimAccess('open-unlim-access'));
                            }}
                        >
                            Go Pro
                        </button>
                    )}
                    {(!user) && <div className='vLine'></div>}
                    {(!user) && <div className='vLine'></div>}
                    {(!user) && (
                        <button className='sign-btn' onClick={() => dispatch(popup('open-signIn'))}>
                            Sign up
                        </button>
                    )}
                    {(user || !guestStatus) && (
                        <>
                            {/* <div className="userProfileBtn"> */}
                            <button className='user-profileBtn' onClick={() => dispatch(popup('open-profile-popup'))}>
                                {user.firstName}
                                <img src={user.avatar ?? userProfileImg} alt="user-avatar" width={32} height={32} style={{
                                    borderRadius: '50%',
                                    marginLeft: '10px'
                                }}/>
                            </button>
                            {/* </div> */}
                            {profilePopup && (
                                <div className='profilePopup' ref={profileRef}>
                                    <div className='profileListPopup'>
                                        <ul>
                                            <li
                                                onClick={() => {
                                                    dispatch(popup('close-profile-popup'));
                                                    //navigate(`/creator-page/ads/${user.id}`);
                                                    navigate('/settings');
                                                }}
                                            >
                                                Settings
                                            </li>
                                            {userFree ? (
                                                <li
                                                    onClick={() => {
                                                        dispatch(popup('close-profile-popup'));
                                                        dispatch(getUnlimAccess('open-unlim-access'));
                                                        localStorage.setItem("unlimAccess", "true");
                                                    }}
                                                >
                                                    My plan
                                                </li>
                                            ) : (
                                              <a
                                                href="https://billing.stripe.com/p/login/cN27tNeco88WgI83cc"
                                                rel="noreferrer"
                                                referrerPolicy="no-referrer"
                                                target="_blank"
                                                style={{
                                                  textDecoration: "none"
                                                }}
                                              >
                                                <li
                                                    onClick={() => {
                                                        //dispatch(popup('close-profile-popup'));
                                                        //navigate('/my-subscription');
                                                    }}
                                                >
                                                    My subscription
                                                </li>
                                              </a>
                                            )}

                                            <li
                                                onClick={() => {
                                                    dispatch(popup('close-profile-popup'));
                                                    dispatch(openSupportModal());
                                                }}
                                            >
                                                Support
                                            </li>
                                                <hr />
                                            <li
                                                onClick={async () => {
                                                    try {
                                                        await triggerLogout();
                                                        dispatch(popup('close-profile-popup'));
                                                        posthog.reset()
                                                        await persistor.purge();
                                                        navigate('/');
                                                        window.location.reload();
                                                    } catch (error) {
                                                        console.log(error)
                                                    }
                                                }}
                                            >
                                                Log out
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </header>
        </div>
    );
};

export default memo(Header);
