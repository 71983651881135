import { createSlice } from '@reduxjs/toolkit';
import { adInspirationApi } from "../services/adinspiration-api";
import { RoleType } from "../model/creatorModel";

export const adminSlice = createSlice({
    name: 'adminSlice',
    initialState: {
        admin: false,
        auth: false
    },
    reducers: {
        adminLogIn: (state, action) => {
            if (action.payload) state.auth = true;
        },
        adminLogOut: (state, action) => {
            if (!action.payload) state.auth = false;
        },
        adminPage: (state, action) => {
            switch (action.payload) {
                case 'open':
                    state.admin = true;

                    break;
                case 'close':
                    state.admin = false;
                    break;
            }
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(adInspirationApi.endpoints?.login.matchFulfilled, (state, {payload}) => {
            if (payload.user.role.type === RoleType.Admin) {
                state.auth = true;
                state.admin = true;
            }
        });
        builder.addMatcher(adInspirationApi.endpoints?.getSession.matchFulfilled, (state, {payload}) => {
            if (payload.user.role.type === RoleType.Admin) {
                state.auth = true;
                state.admin = true;
            }
        });
        builder.addMatcher(adInspirationApi.endpoints?.getSession.matchRejected, (state, {payload}) => {
            state.admin = false;
            state.auth = false;
        });
        builder.addMatcher(adInspirationApi.endpoints?.logout.matchFulfilled, (state, _) => {
            state.admin = false;
            state.auth = false;
        });
    }
});

export const { adminLogIn, adminLogOut, adminPage } = adminSlice.actions;
export default adminSlice.reducer;
