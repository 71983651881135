import { createSlice } from "@reduxjs/toolkit";
import { adInspirationApi } from "../services/adinspiration-api";
import { RoleType } from "../model/creatorModel";


export const logInSlice = createSlice({
    name: 'logInSLice',
    initialState: {
        guestStatus: true,
        userPro: false,
        userFree: false,
    },
    reducers: {
        userLogIn: (state, action) => {
            switch (action.payload) {
                case 'guest':
                    state.guestStatus = true;
                    state.userPro = false;
                    state.userFree = false;
                    break;
                case 'userPro':
                    state.guestStatus = false;
                    state.userPro = true;
                    state.userFree = false;
                    break;
                case 'userFree':

                    state.guestStatus = false;
                    state.userPro = false;
                    state.userFree = true;
                    break;
            }
        }
    }, extraReducers: (builder) => {
        builder.addMatcher(adInspirationApi.endpoints?.getSession.matchFulfilled, (state, {payload}) => {
            switch (payload.user.role.type) {
                case RoleType.Free:
                    state.guestStatus = false;
                    state.userPro = false;
                    state.userFree = true;
                    break;
                case RoleType.Pro:
                    state.guestStatus = false;
                    state.userPro = true;
                    state.userFree = false;
                    break;
                case RoleType.Admin:
                    state.guestStatus = false;
                    state.userPro = true;
                    state.userFree = false;
                    break;
                case RoleType.SubAdmin:
                    state.guestStatus = false;
                    state.userPro = true;
                    state.userFree = false;
                    break;
                default:
                    state.guestStatus = true;
                    state.userPro = false;
                    state.userFree = false;
                    break;
            }
        });
        builder.addMatcher(adInspirationApi.endpoints?.getSession.matchRejected, (state, {payload}) => {
            state.guestStatus = true;
            state.userPro = false;
            state.userFree = false;
            localStorage.setItem('unlimAccess', 'false');
        });
        builder.addMatcher(adInspirationApi.endpoints?.login.matchFulfilled, (state, {payload}) => {
            switch (payload.user.role.type) {
                case RoleType.Free:
                    state.guestStatus = false;
                    state.userPro = false;
                    state.userFree = true;
                    break;
                case RoleType.Pro:
                    state.guestStatus = false;
                    state.userPro = true;
                    state.userFree = false;
                    break;
                case RoleType.Admin:
                    state.guestStatus = false;
                    state.userPro = true;
                    state.userFree = false;
                    break;
                case RoleType.SubAdmin:
                    state.guestStatus = false;
                    state.userPro = true;
                    state.userFree = false;
                    break;
                default:
                    state.guestStatus = true;
                    state.userPro = false;
                    state.userFree = false;
                    break;
            }
        });
        builder.addMatcher(adInspirationApi.endpoints?.logout.matchFulfilled, (state, {payload}) => {
            state.guestStatus = true;
            state.userPro = false;
            state.userFree = false;
        });
    }
})

export const { userLogIn } = logInSlice.actions;
export default logInSlice.reducer;
